
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import Pagination from "@/comp/Pagination/index.vue";
import DTCls from "@/utils/datetime";
import { exportLedgerRtcList, getLedgerRtcList } from "@/api/request/pipelining";
import { saveAs } from "@/api/request/base";

//组件
@Component({
  name: "VoiceVideo",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private rtcTotal: any = {};
  private listLoading: boolean = true;

  //类型
  private typeList: any[] = [
    { value: 1, label: "音频" },
    { value: 2, label: "视频" },
  ];

  //请求数据
  private listQuery: any = {
    //页码数据
    page: 1,
    row: 20,

    //临时数据
    time: [],

    //请求数据
    nickname: "",
    end_time: "",
    start_time: "",
    to_nickname: "",
    identity_type: 0,
    to_identity_type: 0,
    to_userid: undefined,
    userid: undefined,
    type: undefined,

    //是否Excel
    excel: false,
  };

  //时间参数
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
    };
  }

  //发送人身份
  private identityType: any[] = [
    { value: 0, label: "全部发送人身份" },
    { value: 1, label: "常规用户" },
    { value: 2, label: "内部用户" },
    { value: 3, label: "自由博主" },
    { value: 4, label: "签约博主" },
  ];

  //接收人身份
  private toIdentityType: any[] = [
    { value: 0, label: "全部发送人身份" },
    { value: 1, label: "常规用户" },
    { value: 2, label: "内部用户" },
    { value: 3, label: "自由博主" },
    { value: 4, label: "签约博主" },
  ];

  //创建
  created() {
    //获取数据
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.excel = false;
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.start_time = `${this.listQuery.time[0]} 00:00:00`;
      this.listQuery.end_time = `${this.listQuery.time[1]} 23:59:59`;
    } else {
      this.listQuery.start_time = "";
      this.listQuery.end_time = "";
    }
    if (this.listQuery.userid) this.listQuery.userid = Number(this.listQuery.userid);

    //获取数据
    const { data } = await getLedgerRtcList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;
    this.rtcTotal = data.rtc_total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理导出
  private async handleExcel() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.excel = true;
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.start_time = `${this.listQuery.time[0]} 00:00:00`;
      this.listQuery.end_time = `${this.listQuery.time[1]} 23:59:59`;
    } else {
      this.listQuery.start_time = "";
      this.listQuery.end_time = "";
    }
    if (this.listQuery.userid) this.listQuery.userid = Number(this.listQuery.userid);

    //获取数据
    const { data } = await exportLedgerRtcList(this.listQuery);

    //保存文件
    this.listQuery.time ? saveAs(data, `${this.listQuery.time[0]}--${this.listQuery.time[1]}视频语音列表`) : saveAs(data, "视频语音列表总览");

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理重置
  private handleReset(): void {
    //请求数据
    this.listQuery.nickname = "";
    this.listQuery.to_nickname = "";
    this.listQuery.identity_type = 0;
    this.listQuery.userid = undefined;
    this.listQuery.to_identity_type = 0;
    this.listQuery.to_userid = undefined;
    this.listQuery.type = undefined;

    //时间数据
    this.listQuery.time = [];
    this.listQuery.end_time = "";
    this.listQuery.start_time = "";

    //是否Excel
    this.listQuery.excel = false;

    //获取数据
    this.getList();
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //用户类型
  private identityFormat(row: any): string {
    //数据赋值
    let identity = row.identity_type;
    if (identity === 1) return "常规用户";
    if (identity === 2) return "内部用户";
    if (identity === 3) return "自由博主";
    if (identity === 4) return "签约博主";

    //返回空
    return "";
  }

  //处理用户ID
  private useridInputHandler(val: any): any {
    if (val.trim() == "") this.listQuery.userid = undefined;
  }

  //时间格式转化
  private secondsToHMS(seconds: any): string {
    //数据赋值
    var remainingSeconds = seconds % 60;
    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor((seconds % 3600) / 60);
    var formattedTime = hours.toString().padStart(2, "0") + ":" + minutes.toString().padStart(2, "0") + ":" + remainingSeconds.toString().padStart(2, "0");

    //返回结果
    return formattedTime;
  }

  //处理接收人ID
  private toUseridInputHandler(val: any): any {
    if (val.trim() == "") this.listQuery.to_userid = undefined;
  }
}
