import axios from "axios";

//本地导入
import request, { getAppBaseApi, getHeaders } from "../request";

//下拉道具类型
export const getLedgerAll = () =>
  request({
    method: "post",
    url: "/adm/ledger/propconf/all",
  });

//获取类型
export const getLedgerProptypeAll = () =>
  request({
    method: "post",
    url: "/adm/ledger/proptype/all",
  });

//私信下拉
export const getLedgerChatObject = () =>
  request({
    method: "post",
    url: "/adm/ledger/chat/object",
  });

//流水列表
export const getLedgerPropList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/ledger/prop/list",
  });

//流水导出
export const getLedgerListExcel = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/ledger/prop/list`,
  });

//私信列表
export const getLedgerChatList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/ledger/chat/list",
  });

//导出聊天数据
export const exportLedgerChatList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/ledger/chat/list`,
  });

//聊天记录详情
export const getSperviseUserChatHistory = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/chat/history",
  });

//获取博主选配记录
export const getBloggerMatch = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/data/blogger/match",
  });

//获取视频语音记录
export const getLedgerRtcList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/ledger/rtc/list",
  });

//导出视频语音记录
export const exportLedgerRtcList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/ledger/rtc/list`,
  });
